.popup-overlay{
  background-color: rgba(79, 79, 79, 0.3);
}
.popup-content{
  width: 60%;
  height: 70%;
  margin: auto;
  background-color: rgba(79, 79, 79, 0.85);
  border-radius: 8px;
  padding:0.5rem;
}
.image-modal{
  width: 100%;
  height: 100%;
}
.image-modal > .content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.image-modal > .content > .plot {
  max-width:100%;
  max-height: 100%;
  object-fit: contain;
}
.image-modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}