.svg-container {
	 width: 90%;
}
 .svg-container svg {
	 overflow: visible;
	 padding-top: 1em;
}
 .carTopCls1 {
	 fill: #5d5d5d;
	 fill-rule: evenodd;
}
 .carTopCls2 {
	 fill: #767676;
}
 .carTopCls3, .carTopCls6 {
	 fill: none;
	 stroke-miterlimit: 10;
}
 .carTopCls3 {
	 stroke: #d13d64;
	 stroke-width: 0;
}
 .carTopCls4, .carTopCls5 {
	 cursor: pointer;
	 fill: #d42e53;
}
 .carTopCls4 {
	 opacity: 0.5;
	 isolation: isolate;
}
 .carTopCls6 {
	 stroke: #d42e53;
	 stroke-width: 0;
}
 .selectableIcon:hover {
	 cursor: pointer;
}